/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Package } from "react-feather";
import { Link, useParams } from "react-router-dom";

import { Badge, Button, Card } from "react-bootstrap";
import { ApiQuery, DEFUALT_CREATED_AT_SORTING, FilterOp, QueryOperator } from "../../../../redux/models/network.models";
import { Shipment } from "../../../../redux/models/shipment.models";
import { Api } from "../../../../services/services";
import ButtonSpinner from "../../../../components/shared/ButtonSpinner";
import { selectAddressesObject, selectContactsObject } from "../../../../redux/slices/settings";
import { DateService } from "../../../../utils/dateService";
import useAuth from "../../../../hooks/useAuth";
import { selectCurrentAuth0User } from "../../../../redux/slices/organization/organisation";
import { AppState } from "../../../../redux/models/state.models";

function Feed() {
  const { orgCode } = useParams();
  const { user } = useAuth();
  const authId = user?.id || '';
  const currentUser = useSelector((state: AppState) => selectCurrentAuth0User(state, authId));
  const addresses = useSelector(selectAddressesObject);
  const customers = useSelector(selectContactsObject);
  const [shipments, setShipments] = useState<Shipment[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const isNextPage = shipments.length < total;
  const userEntityId = currentUser?.entity_id || '';

  const loadNextPage = async () => {
    setIsLoading(true);
    const response = await Api.Dashboard.shipmentsRequested(page);
    if (response.status === 200) {
      const shipmentData: Shipment[] = response.data.data.items || [];
      const totalData: number = response.data.data.total_count || 0;
      setShipments([...shipments, ...shipmentData]);
      setTotal(totalData);
      setPage(page + 1);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    const load = async () => {
      setIsLoading(true);
      const response = await Api.Dashboard.shipmentsAssigned(userEntityId, 0);
      if (response.status === 200) {
        const shipmentData: Shipment[] = response.data.data.items || [];
        const totalData: number = response.data.data.total_count || 0;
        setShipments(shipmentData);
        setTotal(totalData);
      }
      setIsLoading(false);
    };
    if (userEntityId && userEntityId !== '') load();
  }, [userEntityId]);
  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        {/* <Badge bg="info" className="float-end">
          Today
        </Badge> */}
        <Card.Title className="mb-0">Assigned Shipment</Card.Title>
      </Card.Header>
      <Card.Body>
        {isLoading && <ButtonSpinner />}
        {!isLoading && shipments.length === 0 &&  <small className="text-muted">No assigned shipments</small>}
        <div className="overflow-auto" style={{ maxHeight: '400px' }}>
          {!isLoading && shipments.map((shipment) => {
            const customerId = shipment.data.customer_id || '';
            const customer = customers[customerId]?.data.name || 'No customer set';
            const createdAt = shipment.created_at;
            const originId = shipment.data.origin_id || '';
            const destinationId = shipment.data.destination_id || '';
            const origin = addresses[originId]?.data.samsara_name || 'No origin set';
            const destination = addresses[destinationId]?.data.samsara_name || 'no destination set';
            const route = originId === '' && destinationId === '' ? 'No origin or destination set' : `${origin} to ${destination}`;
            const pickupStr = shipment.data.requested_pick_up_date;
            const deliveryStr = shipment.data.requested_drop_off_date;
            const pickup = pickupStr ? `Requested Pick Up ${DateService.getDoMMMHHmmFormat(pickupStr)}` : '';
            const delivery = deliveryStr ? `Requested Drop Off ${DateService.getDoMMMHHmmFormat(deliveryStr)}` : '';
            let request = 'No requested pick up or delivery time';
            if (pickup !== '') request = pickup;
            if (delivery !== '') request = delivery;
            const timeAgo = DateService.toRelativeTime(createdAt);
            return (
              <div key={shipment.entity_id}>
                <div className="d-flex">
                  {/* <img
                    src={avatar5}
                    width="36"
                    height="36"
                    className="rounded-circle me-2"
                    alt="Ashley Briggs"
                  /> */}
                  <div className="stat me-2">
                    <Package className="align-middle" />
                  </div>
                  <div className="flex-grow-1">
                    <small className="float-end text-navy">{timeAgo}</small>
                    <Link to={`/${orgCode}/shipments/${shipment.entity_id}`}>
                      <strong>{customer}</strong>
                    </Link>
                    <br />
                    {route}
                    <br />
                    <small className="text-muted">{request}</small>
                    <br />
                  </div>
                </div>
                <hr />
              </div>
            )
          })}
        </div>
        {isNextPage && (
          <div className="d-grid">
            <Button variant="primary" onClick={loadNextPage}>Load more</Button>
          </div>
        )}
      </Card.Body>
    </Card>
  );
}

export default Feed;
